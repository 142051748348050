import request from 'utils/request';

export const api = {
  DOWNLOAD_BILL: '/api/postback/billing/downloadBill',
  DOWNLOADE_CLICK_BILL: '/api/postback/billing/downloadClickBill',
  OFFER_CAUSE: '/api/postback/billing/offer/cause',
  SEND_CONVERSION: '/api/postback/billing/sedConversion',
};

export function getOfferCause(offerId, affiliateId) {
  return request({
    url: api.OFFER_CAUSE,
    method: 'get',
    params: {
      offerId,
      affiliateId,
    },
  });
}

export function sendConversion(data) {
  return request({
    url: api.SEND_CONVERSION,
    method: 'post',
    data,
  });
}
